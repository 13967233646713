import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";

const Journey = () => (
    <Layout>
        <Seo title="Journey" />
        <h1>Journey</h1>
        <p>
            A collection of other design work, experiments, animations,
            unfinished side projects and many other things from which I've built
            my career as a designer. Some clickable, most not.
        </p>
    </Layout>
);

export default Journey;
